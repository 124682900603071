import React from 'react';

import { twMerge } from 'tailwind-merge';

import { CheckIcon } from '@bloom/ui/components/Icons/Check';

import { ISelectOption } from './types';

type TPropsBase<T> = Omit<React.ComponentProps<React.ComponentType<unknown>>, 'item'> & {
  'data-testid': string;
  tagName?: string;
};

function MenuItem<T = ISelectOption>(
  props:
    | (TPropsBase<T> & { item: T; onItemClick: (item: T) => void })
    | (TPropsBase<T> & { item?: T; onItemClick?: (item: T) => void })
) {
  const {
    children,
    className,
    isSelected,
    item,
    onClick,
    onItemClick,
    tagName: HtmlTag = 'li',
    ...restProps
  } = props;

  function handleClick(e: React.MouseEvent<HTMLLIElement>) {
    e.stopPropagation();

    if (typeof onClick === 'function') {
      onClick(e);
    }

    if (typeof props.onItemClick === 'function') {
      props.onItemClick(props.item as T);
    }
  }

  return (
    <HtmlTag
      className={twMerge(
        'hover:bg-light-grey flex min-w-0 cursor-pointer items-center gap-2 truncate rounded-sm bg-white px-3 py-2 text-black select-none',
        isSelected ? 'font-medium' : '',
        className
      )}
      onClick={handleClick}
      {...restProps}
    >
      {children}

      {isSelected ? (
        <span className="text-brand ml-auto inline-flex">
          <CheckIcon />
        </span>
      ) : null}
    </HtmlTag>
  );
}

export { MenuItem };
