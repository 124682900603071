'use client';
import React, { useEffect } from 'react';

import {
  autoUpdate,
  flip,
  FloatingNode,
  FloatingPortal,
  FloatingTree,
  offset,
  safePolygon,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  UseFloatingOptions,
  useFloatingParentNodeId,
  useFloatingTree,
  useHover,
  useInteractions,
  useListNavigation,
  useMergeRefs,
  useRole,
  useTypeahead,
} from '@floating-ui/react';
import { twMerge } from 'tailwind-merge';

import { ChevronIcon } from '@bloom/ui/components/Icons/Chevron';
import { PrimaryButton } from '@bloom/ui/components/PrimaryButton';
import { Tooltip } from '@bloom/ui/components/Tooltip';

import { MenuItem } from '@bloom/library/components/Form/SelectV2/MenuItem';

type ContextType = ReturnType<typeof useDropdown> | null;

const DropdownContext = React.createContext<ContextType>(null);

export const useDropdownContext = () => {
  const context = React.useContext(DropdownContext);

  if (context == null) {
    throw new Error('Popover components must be wrapped in <Popover />');
  }

  return context;
};

export function useDropdown(
  props: React.PropsWithChildren<
    Partial<UseFloatingOptions> & { 'data-testid': string; openOnHover?: boolean }
  >
) {
  const {
    children,
    'data-testid': dataTestId,
    onOpenChange,
    open: openOverride = false,
    openOnHover,
    ...restProps
  } = props;

  const [activeIndex, setActiveIndex] = React.useState<number | null>(null);
  const [allowHover, setAllowHover] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const listItemsRef = React.useRef<Array<HTMLButtonElement | null>>([]);
  const listContentRef = React.useRef(
    React.Children.map(children, (child) =>
      React.isValidElement(child) ? child.props.label : null
    ) as Array<string | null>
  );

  const handleSetOpen = (isNewOpen: boolean) => {
    setOpen(isNewOpen);
    onOpenChange && onOpenChange(isNewOpen);
  };

  useEffect(() => {
    if (openOverride) {
      setOpen(true);
    }
  }, [openOverride]);

  const tree = useFloatingTree();
  const nodeId = useFloatingNodeId();
  const parentId = useFloatingParentNodeId();
  const nested = openOnHover === false ? false : parentId != null;

  const floatingProps = useFloating<HTMLButtonElement>({
    middleware: [offset({ alignmentAxis: nested ? -4 : 0, mainAxis: 5 }), flip(), shift()],
    nodeId,
    onOpenChange: handleSetOpen,
    open,
    placement: nested ? 'right-start' : 'bottom-start',
    whileElementsMounted: autoUpdate,
    ...restProps,
  });

  const { context } = floatingProps;

  const interactions = useInteractions([
    useHover(context, {
      delay: { open: 75 },
      enabled: openOnHover || (nested && allowHover),
      handleClose: safePolygon({ blockPointerEvents: true }),
    }),
    useClick(context, {
      event: 'mousedown',
      ignoreMouse: nested,
      toggle: !nested || !allowHover,
    }),
    useRole(context, { role: 'menu' }),
    useDismiss(context),
    useListNavigation(context, {
      activeIndex,
      listRef: listItemsRef,
      nested,
      onNavigate: setActiveIndex,
    }),
    useTypeahead(context, {
      activeIndex,
      listRef: listContentRef,
      onMatch: open ? setActiveIndex : undefined,
    }),
  ]);

  return React.useMemo(
    () => ({
      activeIndex,
      allowHover,
      dataTestId,
      listItemsRef,
      open,
      setActiveIndex,
      setAllowHover,
      setOpen,
      tree,
      ...interactions,
      ...floatingProps,
    }),
    [
      activeIndex,
      allowHover,
      dataTestId,
      floatingProps,
      interactions,
      listItemsRef,
      open,
      setActiveIndex,
      setAllowHover,
      setOpen,
      tree,
    ]
  );
}

interface DropdownTriggerProps {
  asChild?: boolean;
  children: React.ReactNode;
  nested?: boolean;
}

export const DropdownTrigger = React.forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement> &
    DropdownTriggerProps & { variant?: 'primary' | 'secondary' | 'dashed' }
>((props, forwardedRef) => {
  const {
    asChild,
    children,
    className,
    nested = false,
    variant = 'secondary',
    ...restProps
  } = props;

  const context = useDropdownContext();
  const childrenRef = (children as any).ref;
  const ref = useMergeRefs([context.refs.setReference, forwardedRef, childrenRef]);

  // `asChild` allows the user to pass any element as the anchor
  if (asChild && React.isValidElement(children)) {
    return React.cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...restProps,
        ...children.props,
        className: twMerge(className, children.props.className),
        'data-state': context.open ? 'open' : 'closed',
        'data-testid': `${context.dataTestId}-trigger`,
      })
    );
  }

  return (
    <PrimaryButton
      className={className}
      contentClassName="gap-2"
      data-open={context.open ? '' : undefined}
      data-testid={`${context.dataTestId}-trigger`}
      ref={ref}
      {...context.getReferenceProps({
        ...props,
        className: twMerge('!px-6', className),
        onClick(event) {
          event.stopPropagation();
        },
        ...(nested && {
          // Indicates this is a nested <Menu /> acting as a <MenuItem />.
          role: 'menuitem',
        }),
      })}
      variant={variant}
    >
      {children} <ChevronIcon color="currentColor" />
    </PrimaryButton>
  );
});

export const DropdownMenu = React.forwardRef<HTMLElement, React.HTMLProps<HTMLButtonElement>>(
  ({ children, className }, forwardRef) => {
    const { context: floatingContext, ...context } = useDropdownContext();
    const {
      activeIndex,
      allowHover,
      getFloatingProps,
      getItemProps,
      listItemsRef,
      open,
      refs,
      setActiveIndex,
      setAllowHover,
      setOpen,
      strategy,
      x,
      y,
    } = context;

    const tree = useFloatingTree();
    const nodeId = useFloatingNodeId();
    const parentId = useFloatingParentNodeId();

    // Event emitter allows you to communicate across tree components.
    // This effect closes all menus when an item gets clicked anywhere
    // in the tree.
    React.useEffect(() => {
      function handleTreeClick() {
        setOpen(false);
      }

      function onSubMenuOpen(event: { nodeId: string; parentId: string }) {
        if (event.nodeId !== nodeId && event.parentId === parentId) {
          setOpen(false);
        }
      }

      tree?.events.on('click', handleTreeClick);
      tree?.events.on('menuopen', onSubMenuOpen);

      return () => {
        tree?.events.off('click', handleTreeClick);
        tree?.events.off('menuopen', onSubMenuOpen);
      };
    }, [tree, nodeId, parentId, setOpen]);

    React.useEffect(() => {
      if (open) {
        tree?.events.emit('menuopen', {
          nodeId,
          parentId,
        });
      }
    }, [tree, open, nodeId, parentId]);

    // Determine if "hover" logic can run based on the modality of input. This
    // prevents unwanted focus synchronization as menus open and close with
    // keyboard navigation and the cursor is resting on the menu.
    React.useEffect(() => {
      function onPointerMove({ pointerType }: PointerEvent) {
        if (pointerType !== 'touch') {
          setAllowHover(true);
        }
      }

      function onKeyDown() {
        setAllowHover(false);
      }

      window.addEventListener('pointermove', onPointerMove, { capture: true, once: true });
      window.addEventListener('keydown', onKeyDown, true);
      return () => {
        window.removeEventListener('pointermove', onPointerMove, { capture: true });
        window.removeEventListener('keydown', onKeyDown, true);
      };
    }, [allowHover, setAllowHover]);

    const menuRefs = useMergeRefs([refs.setFloating, forwardRef]);

    return (
      <FloatingNode id={nodeId}>
        {open ? (
          <FloatingPortal>
            <div
              data-testid={`${context.dataTestId}-menu`}
              ref={menuRefs}
              style={{
                left: x ?? 0,
                position: strategy,
                top: y ?? 0,
              }}
              {...getFloatingProps({
                className: twMerge(
                  'bg-white p-2 list-unstyled rounded-lg shadow-lg outline-hidden',
                  className
                ),
                // Pressing tab dismisses the menu due to the modal
                // focus management on the root menu.
                onKeyDown(event) {
                  if (event.key === 'Tab') {
                    setOpen(false);

                    if (event.shiftKey) {
                      event.preventDefault();
                    }
                  }
                },
              })}
            >
              {React.Children.map(
                children,
                (child, index) =>
                  React.isValidElement(child) &&
                  React.cloneElement(
                    child,
                    getItemProps({
                      onClick(event) {
                        child.props.onClick?.(event);
                        tree?.events.emit('click');
                      },
                      // Allow focus synchronization if the cursor did not move.
                      onMouseEnter() {
                        if (allowHover && open) {
                          setActiveIndex(index);
                        }
                      },
                      ref(node: HTMLButtonElement) {
                        listItemsRef.current[index] = node;
                      },
                      role: 'menuitem',
                      tabIndex: activeIndex === index ? 0 : -1,
                    })
                  )
              )}
            </div>
          </FloatingPortal>
        ) : null}
      </FloatingNode>
    );
  }
);

export const DropdownMenuItem: React.FC<
  React.ComponentProps<typeof MenuItem> & {
    asChild?: boolean;
    className?: string;
    disabled?: boolean;
    children: React.ReactNode;
    disabledTooltip?: React.ReactNode;
  }
> = React.forwardRef((props, forwardedRef) => {
  const {
    asChild,
    children,
    disabled,
    disabledTooltip,
    onClick: floatingOnClick,
    onItemClick,
    ...restProps
  } = props;

  // `asChild` allows the user to pass any element as the anchor
  if (asChild && React.isValidElement(children)) {
    return React.cloneElement(children, {
      ...restProps,
      ...children.props,
      onClick: (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        floatingOnClick(e);
        if ('onClick' in children.props) {
          children.props.onClick();
        }
      },
      ref: forwardedRef,
      role: 'menuitem',
    });
  }

  if (disabled && disabledTooltip) {
    return (
      <MenuItem
        {...restProps}
        className={twMerge(
          'min-w-52 font-medium',
          disabled ? '!cursor-default opacity-50' : '',
          props.className
        )}
        disabled
        onItemClick={disabled ? undefined : onItemClick}
        ref={forwardedRef}
        role="menuitem"
      >
        <Tooltip
          className="flex w-full justify-between"
          content={disabledTooltip}
          offsetProps={{ mainAxis: 20 }}
          placement="right"
        >
          {children}
        </Tooltip>
      </MenuItem>
    );
  }

  return (
    <MenuItem
      {...restProps}
      className={twMerge(
        'min-w-52 font-medium',
        disabled ? '!cursor-not-allowed opacity-50' : '',
        props.className
      )}
      disabled={disabled}
      onClick={(e) => {
        e.stopPropagation();
        floatingOnClick(e);
      }}
      onItemClick={disabled ? undefined : onItemClick}
      ref={forwardedRef}
      role="menuitem"
    >
      {children}
    </MenuItem>
  );
});

export const Dropdown: React.FC<
  React.PropsWithChildren<Partial<UseFloatingOptions>> & {
    'data-testid': string;
    openOnHover?: boolean;
  }
> = (props) => {
  const { children, ...dropdownOptions } = props;

  const parentId = useFloatingParentNodeId();
  const dropdown = useDropdown({ children, ...dropdownOptions });

  return (
    <DropdownContext.Provider value={dropdown}>
      {parentId == null ? <FloatingTree>{children}</FloatingTree> : children}
    </DropdownContext.Provider>
  );
};
